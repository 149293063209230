import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [
    'currentSelected',
    'currentSelectedAddress',
    'cardNumber',
    'vcodeNumber',
    'requestLink',
    'requestcustomerLink',
    'currentProduct',
    'mobileLink',
    'rating',
    'quickform',
    'currentPayment',
    'currentShippingAddress',
    'shippingPayment',
    'shippingService',
    'modalPopup',
    'eventPayment',
    'eventShippingAddress'
  ]


  next(){
    $(".selected").removeClass("selected hover:bg-gray-50 bg-yellow-50 bg-opacity-50 hover:bg-gray-50 rounded-b-none rounded-md");
    this.currentSelectedTarget.closest("label").className += " selected bg-yellow-50 bg-opacity-50 hover:bg-gray-50 rounded-b-none rounded-md "
    if($('input[name="gift[payment_source_id]"]:checked').val() == 'new_payment' || $('input[name="payment_source_id"]:checked').val() == 'new_payment') {
      $('.new-payment').show();
    }else{
      $('.new-payment').hide();
    }
  }

  select_payment(){
    $(".selected").removeClass("selected hover:bg-gray-50 bg-yellow-50 bg-opacity-50 hover:bg-gray-50 rounded-b-none rounded-md");
    this.currentPaymentTarget.closest("label").className += " selected bg-yellow-50 bg-opacity-50 hover:bg-gray-50 rounded-b-none rounded-md "
    if($('input[name="subscriptions_crate[payment_source_id]"]:checked').val() == 'new_payment' || $('input[name="payment_source_id"]:checked').val() == 'new_payment') {
      $('.new-payment').show();
    }else{
      $('.new-payment').hide();
    }
  }

  event_payment(){
    $(".selected").removeClass("selected hover:bg-gray-50 bg-yellow-50 bg-opacity-50 hover:bg-gray-50 rounded-b-none rounded-md");
    this.eventPaymentTarget.closest("label").className += " selected bg-yellow-50 bg-opacity-50 hover:bg-gray-50 rounded-b-none rounded-md "
    if($('input[name="event[payment_source_id]"]:checked').val() == 'new_payment' ) {
      $('.new-payment').show();
    }else{
      $('.new-payment').hide();
    }
  }


  ship_payment(){
    $(".selected").removeClass("selected hover:bg-gray-50 bg-yellow-50 bg-opacity-50 hover:bg-gray-50 rounded-b-none rounded-md");
    this.shippingPaymentTarget.closest("label").className += " selected bg-yellow-50 bg-opacity-50 hover:bg-gray-50 rounded-b-none rounded-md "
    if($('input[name="shipping_request[payment_source_id]"]:checked').val() == 'new_payment' || $('input[name="payment_source_id"]:checked').val() == 'new_payment') {
      $('.new-payment').show();
    }else{
      $('.new-payment').hide();
    }
  }

  select_new_address(){
    $(".selected").removeClass("selected bg-yellow-50 bg-opacity-50");
    this.currentSelectedAddressTarget.closest("label").className +=  ' selected bg-yellow-50 bg-opacity-50'
    if($('input[name="subscriptions_crate[shipping_address_id]"]:checked').val() == 'new_address') {
      $('.new-address').show();
    }else{
      $('.new-address').hide();
    }
  }

  event_address(){
    $(".selected").removeClass("selected bg-yellow-50 bg-opacity-50");
    this.eventShippingAddressTarget.closest("label").className +=  ' selected bg-yellow-50 bg-opacity-50'
    if($('input[name="event[shipping_address_id]"]:checked').val() == 'new_address') {
      $('.new-address').show();
    }else{
      $('.new-address').hide();
    }
  }

  hide_popup(){
    $('#ca-popup').addClass('hidden');
    $('#local-popup').hide();
  }

  shipping_new_address(){
    $(".selected").removeClass("selected bg-yellow-50 bg-opacity-50");
    this.currentShippingAddressTarget.closest("label").className +=  ' selected bg-yellow-50 bg-opacity-50'
    if($('input[name="shipping_request[shipping_address_id]"]:checked').val() == 'new_address') {
      $('.new-address').show();
    }else{
      $('.new-address').hide();
      if (this.currentShippingAddressTarget.dataset.state == 'CA'){
        $('#ca-popup').removeClass('hidden');
      }
    }
  }
  modify_input(){
    let val = this.cardNumberTarget.value
    let newval = '';
    val = val.replace(/\s/g, '');

    // iterate to letter-spacing after every 4 digits
    for(let i = 0; i < val.length; i++) {
      if(i%4 == 0 && i > 0) newval = newval.concat(' ');
      newval = newval.concat(val[i]);
    }

    // format in same input field
    this.cardNumberTarget.value = newval
  }

  modify_voucher_code(){
    var val = this.vcodeNumberTarget.value
    var newval = '';
    val = val.replace(/\s/g, '');

    // iterate to letter-spacing after every 4 digits
    for(var i = 0; i < val.length; i++) {
      if(i%4 == 0 && i > 0) newval = newval.concat(' ');
      newval = newval.concat(val[i]);
    }

    // format in same input field
    $(this).val(newval);
    // format in same input field
    this.vcodeNumberTarget.value = newval
  }

  send_request(){
    $.ajax({
      url: this.requestLinkTarget.dataset.link,
      type: "GET",
      processData: false,
      contentType: false,
      dataType: 'script'
    });
  }

  send_customer_request(){
    window.location = this.requestcustomerLinkTarget.dataset.link
  }

  change_product(){
    $('body').addClass('pending')
    var form  = this.currentProductTarget.closest('form')
    var data = $(form).serialize()
    window.location.search = data
  }

  modify_service(){
    $('#shipping-change-section').removeClass('hidden');
    var form  = this.shippingServiceTarget.closest('form')
    var data = $(form).serialize()
    $.ajax({
      url:  window.location.pathname,
      type: 'GET',
      dataType: 'script',
      data: data
    });
  }

  send_request_mobile(){
    window.location = this.mobileLinkTarget.value
  }

  send_rating(){
    var form = this.ratingTarget.closest('form')
    $(form).submit();
  }

  show_form(){
    var id = this.quickformTarget.className.baseVal.split(' ')[6].split('-')[2]
    $("#change_name-"+id).removeClass('hidden');
    $(".edit-icon-"+ id).addClass('hidden');
    $(".crate-name-"+ id).addClass('hidden');
  }
}
